import React from "react";
import {RouterModule} from "../model/RouterModel";
import myApp from "../static/js/MyApp";
import {Button, Col, Image, Input, message, Row, Typography} from "antd";
import about_us from '../static/image/aboutus/about_us.png'
import {
    GlobalOutlined,
    HomeOutlined,
    MailOutlined,
    PhoneOutlined,
    ScanOutlined, SendOutlined,
    UserOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import AMapLoader from '@amap/amap-jsapi-loader';
import "../static/css/AboutUs.css"

const {Paragraph} = Typography;

class AboutUs extends React.Component {

    constructor(props) {
        super(props);
        this.map = {};
        this.state = {
            rowSpan: [6, 12, 24],

            name: '',
            tel: '',
            email: '',
            country: '',
            phone: '',
            fax: '',
            company: '',
            message: '',
        }
    }

    tapSendMessage = async () => {
        const param = new FormData();
        param.append("name", this.state.name);
        param.append("tel", this.state.tel);
        param.append("email", this.state.email);
        param.append("country", this.state.country);
        param.append("phone", this.state.phone);
        param.append("fax", this.state.fax);
        param.append("company", this.state.company);
        param.append("message", this.state.message);
        const response = await myApp.sendMessage(param);
        if (response.code === 0) {
            message.success(myApp.messageSuccess)
        } else {
            message.warning(myApp.messageErrors);
        }
    }

    //渲染成功后进行map对象的创建
    componentDidMount() {
        this.setSpan();
        AMapLoader.load({
            key: "4b068d48324e48e69afe08d49c61f974",
            version: "1.4.15",
            plugins: ['Map3D', 'AMap.Weather'],
        }).then((AMap) => {
            this.map = new AMap.Map("container", {
                zoom: 14,
                center: [120.114353, 29.31268],
                lang: 'en',
            });
        }).catch(e => {
            console.log(e);
        })

        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        const width = window.innerWidth;
        if (width < 500) {
            this.setState({rowSpan: [24, 24, 24]})
        } else {
            this.setState({rowSpan: [6, 12, 24]})
        }

    }

    render() {
        return (
            <div>
                <div className='content-max-width padding-tb margin-tb'>
                    <div className='text-title text-align-center text-color-yellow margin-tb padding'>
                        {myApp.G_COMPANY_NAME}
                    </div>
                    <Paragraph className='padding'>
                        {myApp.G_COMPANY_NAME} ICA global trading, is a sub project of Alphalo(Yiwu) International Trade
                        Co., Ltd., and the service content of ICA is mainly to export goods from Chinese factories to
                        other countries worldwide.
                    </Paragraph>
                    <Paragraph className='padding'>
                        Alphalo (Yiwu) International Trade Co., Ltd. is located in Yiwu, the largest small commodity
                        distribution center in the world and the largest market determined by the United Nations, the
                        world bank and other international authorities. It is an enterprise focusing on import and
                        export trade.
                    </Paragraph>
                    <Image src={about_us}></Image>

                    <div className='margin-tb padding'>
                        <Row gutter={16} className='margin-tb padding-top'>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[0]}>
                                <Input size="large" placeholder="Name" prefix={<UserOutlined/>} onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[0]}>
                                <Input size="large" placeholder="Tel" prefix={<PhoneOutlined/>} onChange={(e) => {
                                    this.setState({tel: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[0]}>
                                <Input size="large" placeholder="E-mail" prefix={<MailOutlined/>} onChange={(e) => {
                                    this.setState({email: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[0]}>
                                <Input size="large" placeholder="Country" prefix={<HomeOutlined/>} onChange={(e) => {
                                    this.setState({country: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[0]}>
                                <Input size="large" placeholder="Phone" prefix={<WhatsAppOutlined/>} onChange={(e) => {
                                    this.setState({phone: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[0]}>
                                <Input size="large" placeholder="Fax" prefix={<ScanOutlined/>} onChange={(e) => {
                                    this.setState({fax: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[1]}>
                                <Input size="large" placeholder="Company" prefix={<GlobalOutlined/>} onChange={(e) => {
                                    this.setState({company: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[2]}>
                                <TextArea placeholder="Message" autoSize={{minRows: 3, maxRows: 5}} onChange={(e) => {
                                    this.setState({message: e.target.value})
                                }}/>
                            </Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[1]}></Col>
                            <Col className="margin-tb-sm" span={this.state.rowSpan[1]}>
                                <Button icon={<SendOutlined/>} size="large" className='bg-yellow' onClick={this.tapSendMessage}
                                        style={{width: '100%'}}>
                                    SEND
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='text-align-center'>
                    <div id="container" tabIndex="0" lang='en'></div>
                </div>
            </div>
        )
    }
}

export default RouterModule(AboutUs)