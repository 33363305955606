import React from "react";
import {RouterModule} from "../model/RouterModel";
import {Button, Carousel, Col, Divider, Image, message, Row, Tag, Typography} from "antd";
import myApp from "../static/js/MyApp";

const {Title, Paragraph} = Typography;

class ProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            param: {},
            productImage: [],
            productKey: [],
            spanNumber: [14, 10]
        }
    }

    componentDidMount() {
        const pro = this.props.router.location.state.param;
        this.setState({
            param: pro,
            productImage: myApp.isEmpty(pro.product_image) ? [] : pro.product_image,
            productKey: myApp.isEmpty(pro.product_key) ? [] : pro.product_key.split(","),
        })

        this.setSpan();
        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        let width = window.innerWidth;
        if (width < 900) this.setState({spanNumber: [24, 24]});
        if (width > 900) this.setState({spanNumber: [14, 10]});
    }

    tapProductNext = () => {
        const url = `${myApp.G_API_HOST}/api/cms/getProductNext?id=${this.state.param.id}`
        myApp.httpGet(url).then((res) => {
            if (res.code === 0) {
                const db = res.data;
                if (db.list.length > 0) {
                    const pro = db.list[0];
                    this.setState({
                        param: pro,
                        productImage: myApp.isEmpty(pro.product_image) ? [] : pro.product_image.split(","),
                        productKey: myApp.isEmpty(pro.product_key) ? [] : pro.product_key.split(","),
                    })
                } else {
                    message.warning('No more product information')
                }
            } else {
                message.warning(`查询异常，请联络管理人员/If an exception occurs, contact the administrator`)
            }
        })
    }

    contentStyle = {minHeight: '20vh', textAlign: 'center'};

    render() {
        const proCarousel = this.state.productImage.map((item, index) => {
            return <div key={index}>
                <h3 style={this.contentStyle}>
                    <Image height={'45vh'} src={item}/>
                </h3>
            </div>
        })

        const proKey = this.state.productKey.map((item, index) => {
            return <Tag color="default" key={index} className='padding'>{item}</Tag>
        })

        return (
            <div className='margin'>
                <Row>
                    <Col span={this.state.spanNumber[0]}>
                        <Carousel autoplay className='border'>
                            {proCarousel}
                        </Carousel>
                    </Col>
                    <Col span={this.state.spanNumber[1]}>
                        <div className='margin-sm'>
                            <Title>{this.state.param.product_name}</Title>
                            <Paragraph>{this.state.param.product_abstract}</Paragraph>

                            <Row gutter={16}>
                                <Col span={4} className='margin-tb'>Share:</Col>
                                <Col span={20} className='margin-tb'>
                                    <div className='footer_icon text-color-yellow'>
                                        <i className='margin-right iconfont icon-facebook'/>
                                        <i className='margin-right iconfont icon-hi'/>
                                        <i className='margin-right iconfont icon-telegram'/>
                                        <i className='margin-right iconfont icon-weixin'/>
                                        <i className='margin-right iconfont icon-whatsapp'/>
                                        <i className='margin-right iconfont icon-line-line'/>
                                        <i className='margin-right iconfont icon-kakao-talk-line'/>
                                        <i className='margin-right iconfont icon-youtube'/>
                                        <i className='iconfont icon-LinkedinIn'/>
                                    </div>
                                </Col>
                                <Col span={12} className='text-align-center'>
                                    <Button className='bg-yellow' size={"large"} onClick={() => {
                                        this.props.router.navigation("/ContactUs")
                                    }}>Inquire Now</Button>
                                </Col>
                                <Col span={12} className='text-align-center'>
                                    <Button className='bg-yellow' size={"large"} onClick={this.tapProductNext}>Next
                                        Product</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left"></Divider>
                        <Typography.Title level={2}>
                            <div className={'text-color-yellow'}>ATTRIBUTE</div>
                        </Typography.Title>
                        <Paragraph className='text-align-left'>Color:{this.state.param.product_color}</Paragraph>

                        <Divider orientation="left"></Divider>
                        <Typography.Title level={2}>
                            <div className={'text-color-yellow'}>Product Details</div>
                        </Typography.Title>
                        <div>
                            <p dangerouslySetInnerHTML={{__html: this.state.param.product_body}}></p>
                        </div>

                        <div className='padding-tb margin-top'>{proKey}</div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default RouterModule(ProductDetails)