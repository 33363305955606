import React from "react";
import {useLocation, useNavigate, useParams} from "react-router";

export const RouterModule = (Component) => {
    function ComponentRouter(props) {
        const navigation = useNavigate(),
            location = useLocation(),
            params = useParams()

        return (<Component {...props} router={{navigation, location, params}}/>)
    }

    return ComponentRouter;
}