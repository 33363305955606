import axios from "axios"

class MyApp {

    G_TITLE = "ICA GLOBAL TRADING"
    G_ADDRESS = "Building 59, Jiangdong Street, Yiwu City, Zhejiang Province"
    G_COMPANY_NAME = "Alphalo (Yiwu) International Trade Co., Ltd."
    G_WHATAPP = "17732097106 (China)"
    G_WECHAT = "17732097106 (China)"
    G_TEL = "17732097106 (China)"
    G_FAX = "17732097106 (China)"
    G_Skype = "17732097106 (China)"
    G_Email = "17732097106 (China)"

    // G_API_HOST = "http://localhost/ReactIIcaWebsite/admin-server/public";
    G_API_HOST = "https://icaglobaltrading.com/api/public";

    messageSuccess = 'Thank you for your consultation! Please wait for our reply!'
    messageError = 'membercode'
    messageErrors = 'The submission failed. Please confirm the information and try again！'

    isEmpty(str) {
        if (typeof str == "string" || typeof str == "undefined") {
            return str === null || str === undefined || str === 'undefined' || str === '';
        } else {
            return str.length <= 0;
        }
    }

    httpGet(url) {
        return new Promise(resolve => {
            axios.get(url).then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                }
            })
        })
    }

    httpPost(url, param) {
        return new Promise(resolve => {
            axios.post(url, param).then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                }
            })
        })
    }

    /**
     *
     * @param param
     * {
     *      name        姓名
     *      tel         电话
     *      email       邮箱
     *      country     国家
     *      phone       手机
     *      fax         传真
     *      company     公司
     *      message     留言
     * }
     * @returns {Promise<void>}
     */
    async sendMessage(param) {
        return new Promise(async (resolve) => {
            const url = `${this.G_API_HOST}/api/cms/newMessage`;
            const result = await this.httpPost(url, param);
            resolve(result)
        })
    }

}

export default new MyApp()