import './App.css';
import React from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import Home from "./view/Home";
import AboutUs from "./view/AboutUs";
import IndexHome from "./view/IndexHome";
import Products from "./view/Products";
import Application from "./view/Application";
import News from "./view/News";
import ContactUs from "./view/ContactUs";
import ProductDetails from "./view/ProductDetails";
import DetailPage from "./view/DetailPage";
import ProductList from "./view/ProductList";

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route exact path="/" element={<IndexHome/>}>
                    <Route exact path="" element={<Home/>}/>
                    <Route exact path="AboutUs" element={<AboutUs/>}/>
                    <Route exact path="Products" element={<Products/>}>
                        <Route exact path=":ids" element={<ProductList/>}/>
                        <Route exact path="ProductDetails/:id" element={<ProductDetails/>}/>
                    </Route>
                    <Route exact path="Application" element={<Application/>}/>
                    <Route exact path="News" element={<News/>}/>
                    <Route exact path="ContactUs" element={<ContactUs/>}/>
                    <Route exact path="DetailPage" element={<DetailPage/>}/>
                </Route>
            </Routes>
        </HashRouter>
    );
}

export default App;
