import React from "react";
import {RouterModule} from "../model/RouterModel";
import {Button, Card, Carousel, Col, Input, message, Row, Typography} from "antd";
import "../static/css/Home.css"
import myApp from "../static/js/MyApp";
import banner01 from '../static/image/swiper/banner_001.png'
import banner02 from '../static/image/swiper/banner_002.png'
import banner03 from '../static/image/swiper/banner_003.png'
import banner04 from '../static/image/swiper/banner_004.png'

import syn from "../static/image/ls/syn.jpg"
import Meta from "antd/es/card/Meta";
import {
    ArrowRightOutlined,
    FieldTimeOutlined,
    HomeOutlined,
    MailOutlined, MessageOutlined, SafetyOutlined,
    UserOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const {Paragraph} = Typography;

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            proList: [],     //产品数据列表
            hotProductHtml: [],  //产品数据展示HTML
            proTopList: [],   //置顶数据
            proTopHtml: [],  //置顶产品展示数据
            newsHtml: [],    //新闻数据展示HTML
            width: window.innerWidth,
            loading: false,
            proSpan: 24,
            topProSpan: 8,
            hotProSpan: 6,
            sendSpan: 6,
            newSpan: [8, 16],
            ProductReponse: {},
            newsReponse: {},

            name: '',
            tel: '',
            email: '',
            country: '',
            phone: '',
            fax: '',
            company: '',
            message: '',

            CAPTCHA: '',
            CAPTCHAS: Math.ceil(Math.random() * 10000),
        }
    }

    tapSubEvent = async () => {
        if (`${this.state.CAPTCHAS}` === this.state.CAPTCHA) {
            const param = new FormData();
            param.append("name", this.state.name);
            param.append("tel", this.state.tel);
            param.append("email", this.state.email);
            param.append("country", this.state.country);
            param.append("phone", this.state.phone);
            param.append("fax", this.state.fax);
            param.append("company", this.state.company);
            param.append("message", this.state.message);
            const response = await myApp.sendMessage(param);
            if (response.code === 0) {
                message.success(myApp.messageSuccess)
            } else {
                message.warning(myApp.messageErrors);
            }
        } else {
            message.warning(myApp.messageError);
        }
    }

    componentDidMount() {
        this.setSpan();
        this.getProductDB();
        this.getNesDb();
        this.onInnerWidth();
    }

    onInnerWidth = () => {
        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        this.setState({width: window.innerWidth})
        if (this.state.width < 900) {
            this.setState({topProSpan: 12, hotProSpan: 12});
        }
        if (this.state.width < 500) {
            this.setState({topProSpan: 24, hotProSpan: 24, sendSpan: 24, newSpan: [24, 24]});
        }
        if (this.state.width > 900) {
            this.setState({topProSpan: 8, hotProSpan: 6, sendSpan: 6, newSpan: [8, 16]});
        }
        this.pageInitEvent();
    }

    pageInitEvent = () => {
        this.getProduct();
        this.getNews();
    }

    tapToPageAboutUs = () => {
        this.props.router.navigation("AboutUs")
    }

    getProductDB = async () => {
        let url = `${myApp.G_API_HOST}/api/cms/getProduct`, body = {page: 1, size: 8}
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&";
            url += join + key + "=" + body[key];
        }
        const ProductReponse = await myApp.httpGet(url);
        // console.log(ProductReponse)
        this.setState({ProductReponse: ProductReponse})
        this.getProduct();
    }

    getProduct = () => {
        const res = this.state.ProductReponse
        if (res.code === 0) {
            const db = res.data
            // 格式化商品数据
            let proList = db.list.map(item => {
                item.product_color = myApp.isEmpty(item.product_color) ? [] : item.product_color.split(",")
                let img = item.product_image;
                item.product_image = myApp.isEmpty(img) ? [] : typeof img == 'object' ? img : img.split(",")
                item.product_key = myApp.isEmpty(item.product_key) ? [] : item.product_key.split(",")
                return item;
            })
            // 生成展示数据
            let hotProduct = proList.map((item, index) => {
                return <Col className="gutter-row" span={this.state.hotProSpan} key={index}>
                    <Card hoverable className='margin-tb hot-product' style={{width: '100%'}}
                          cover={<img alt="example" src={item.product_image[0]}/>}>
                        <Meta title="Europe Street beat" description={item.product_abstract}/>
                    </Card>
                </Col>
            });
            // 生成展示置顶数据
            let topList = db.top.map(item => {
                item.product_color = myApp.isEmpty(item.product_color) ? [] : item.product_color.split(",")
                let img = item.product_image;
                item.product_image = myApp.isEmpty(item.product_image) ? [] : typeof img == "object" ? img : item.product_image.split(",")
                item.product_key = myApp.isEmpty(item.product_key) ? [] : item.product_key.split(",")
                return item;
            })

            let proTopHmtl = topList.map((item, index) => {
                return <Col span={this.state.topProSpan} key={index}>
                    <div className='product background_style'
                         style={{backgroundImage: 'url(' + item.product_image[0] + ')'}}>
                        <div className='product_hover'>
                            <div className='product_title'>{item.product_name}</div>
                            <div className='product_body padding'>{item.product_abstract}</div>
                        </div>
                    </div>
                </Col>
            })

            this.setState({
                proTopList: topList,
                proTopHtml: proTopHmtl,
                proList: proList,
                productHtml: hotProduct,
            })
        }
    }

    getNesDb = async () => {
        let url = `${myApp.G_API_HOST}/api/cms/getNew`, body = {page: 1, size: 4};
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&";
            url += join + key + "=" + body[key];
        }
        const newsReponse = await myApp.httpGet(url);
        this.setState({newsReponse: newsReponse})
        this.getNews();
    }

    getNews = async () => {
        const res = this.state.newsReponse
        if (res.code === 0) {
            const db = res.data,
                dbList = db.list;

            let i = 0, newList = dbList.map(item => {
                i++;
                if (i % 2 > 0) {
                    return <div className='margin-tb' key={item.id}>
                        <Row>
                            <Col span={this.state.newSpan[0]}>
                                <div style={{backgroundImage: 'url(' + syn + ')'}}
                                     className='background_style'></div>
                            </Col>
                            <Col span={this.state.newSpan[1]} className='bg-color-white'>
                                <div className='margin padding text-align-left'>
                                    <div className='sub-text-title font_weight_bold'>{item.n_title}</div>
                                    <div className='margin-tb-sm'>
                                        <FieldTimeOutlined className='margin-right'/> {item.update_time}
                                    </div>
                                    <div>{item.n_abstract}</div>
                                    <div className='margin-top padding-top button-main-no-border text-color-yellow'>
                                        Learn More <ArrowRightOutlined/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                } else {
                    return <div className='margin-tb' key={item.id}>
                        <Row>
                            <Col span={this.state.newSpan[1]} className='bg-color-white'>
                                <div className='margin padding text-align-left'>
                                    <div className='sub-text-title font_weight_bold'>{item.n_title}</div>
                                    <div className='margin-tb-sm'>
                                        <FieldTimeOutlined className='margin-right'/> {item.update_time}
                                    </div>
                                    <div>{item.n_abstract}</div>
                                    <div className='margin-top padding-top button-main-no-border text-color-yellow'>
                                        Learn More <ArrowRightOutlined/>
                                    </div>
                                </div>
                            </Col>
                            <Col span={this.state.newSpan[0]}>
                                <div style={{backgroundImage: 'url(' + syn + ')'}}
                                     className='background_style'></div>
                            </Col>
                        </Row>
                    </div>
                }
            });
            this.setState({newsHtml: newList});
        }
    }

    onChangeEvent = (currentSlide) => {
        // console.log(currentSlide)
    }

    render() {
        const bannerImage = [
                {img: banner01, title: myApp.G_COMPANY_NAME, body: 'This is the show promotion word'},
                {img: banner02, title: myApp.G_COMPANY_NAME, body: 'This is the show promotion word'},
                {img: banner03, title: myApp.G_COMPANY_NAME, body: 'This is the show promotion word'},
                {img: banner04, title: myApp.G_COMPANY_NAME, body: 'This is the show promotion word'},
            ],
            bannerDiv = bannerImage.map((item, index) => {
                return <div key={index}>
                    <div style={{backgroundImage: 'url(' + item.img + ')'}} className='banner_image'>
                        <div className='banner_card'>
                            <div className='banner_title'>{item.title}</div>
                            <div className='banner_body'>{item.body}</div>
                        </div>
                    </div>
                </div>
            })

        return <div>
            {/*swiper*/}
            <Carousel afterChange={this.onChangeEvent} autoplay draggable={true}>
                {bannerDiv}
            </Carousel>

            {/*product hot*/}
            <div>
                <Row>{this.state.proTopHtml}</Row>
            </div>

            <div className='content-max-width'>
                {/*product list*/}
                <div className='padding-top margin-top'>
                    <div className='text-align-center margin-tb padding-tb'>
                        <div className='banner_title'>Hot Products</div>
                        <Paragraph>
                            The company is mainly engaged in metal building and decoration materials, expo & exhibition
                            materials such as aluminum products, galvanized steel products, iron and steel products etc.
                            Cooperative factories are all over the country to provide buyers with national goods
                            transfer, national price comparison and multi category shipping services.

                            The company has the ability to operate its own import and export, and cooperates with a
                            number of international freight forwarders. The freight forwarding company is located in
                            Tianjin port, Ningbo port, Shenzhen port, etc. The products are exported to Africa, Europe
                            and America. It provides buyers with a full set of LCL and space booking services to realize
                            door-to-door one-stop service.
                        </Paragraph>
                        <div className='transverse-line margin-tb'></div>
                    </div>
                    <div className='padding-lr'>
                        <Row gutter={16}>
                            {this.state.productHtml}
                        </Row>
                    </div>
                    <div className='margin padding text-align-center'>
                        <div className='button-main'>View More <ArrowRightOutlined/></div>
                    </div>
                </div>
            </div>

            {/*enterprise info*/}
            <div className='enterprise padding'>
                <div className='content-max-width padding-tb margin-top text-align-center'>
                    <div className='margin padding'></div>
                    <div className='text-title text-color-yellow font_weight_bold margin-top padding-top'>
                        {myApp.G_COMPANY_NAME}
                    </div>
                    <Paragraph className='tx-color-white padding-tb margin-tb'>
                        ICA global trading, is a sub project of Alphalo(Yiwu) International Trade Co., Ltd., and the
                        service content of ICA is mainly to export goods from Chinese factories to other countries
                        worldwide.

                        Alphalo (Yiwu) International Trade Co., Ltd. is located in Yiwu, the largest small commodity
                        distribution center in the world and the largest market determined by the United Nations, the
                        world bank and other international authorities. It is an enterprise focusing on import and
                        export trade.
                    </Paragraph>
                    <div className='margin padding text-align-center'>
                        <div className='button-main button-main-yellow'>View More <ArrowRightOutlined/></div>
                    </div>
                </div>
            </div>
            {/*new list*/}
            <div className='padding bg-yellow'>
                <div className='content-max-width padding-tb margin-tb text-align-center'>
                    <div className='text-title font_weight_bold margin-top padding-top'>
                        News
                    </div>
                    <Paragraph className='padding-tb margin-tb'>
                        {myApp.G_COMPANY_NAME} was established by foreign trade staff after studying from overseas in
                        2008, the team was composed.
                    </Paragraph>
                    <div className='margin-tb padding-tb'>
                        {this.state.newsHtml}
                    </div>
                </div>
            </div>
            {/*Contact us*/}
            <div className='padding'>
                <div className='content-max-width padding-tb margin-tb text-align-center'>
                    <div className='text-title font_weight_bold margin-top padding-top'>
                        Contact us
                    </div>
                    <Paragraph className='padding-tb margin-tb'>
                        {myApp.G_COMPANY_NAME} was established by foreign trade staff after studying from overseas in
                        2008, the team was composed.
                    </Paragraph>
                    <div className='margin-tb padding-tb'>
                        <div className='margin-tb padding-tb'>
                            <Row gutter={16}>
                                <Col span={this.state.hotProSpan} className='margin-bottom-sm'>
                                    <Input size="large" placeholder="Name" prefix={<UserOutlined/>} onChange={(e) => {
                                        this.setState({name: e.target.value})
                                    }}/>
                                </Col>
                                <Col span={this.state.hotProSpan} className='margin-bottom-sm'>
                                    <Input size="large" placeholder="E-mail" prefix={<MailOutlined/>} onChange={(e) => {
                                        this.setState({email: e.target.value})
                                    }}/>
                                </Col>
                                <Col span={this.state.hotProSpan} className='margin-bottom-sm'>
                                    <Input size="large" placeholder="Country" prefix={<HomeOutlined/>}
                                           onChange={(e) => {
                                               this.setState({country: e.target.value})
                                           }}/>
                                </Col>
                                <Col span={this.state.hotProSpan} className='margin-bottom-sm'>
                                    <Input size="large" placeholder="Tel" prefix={<WhatsAppOutlined/>}
                                           onChange={(e) => {
                                               this.setState({tel: e.target.value})
                                           }}/>
                                </Col>
                                <Col span={24} className='margin-tb'>
                                    <TextArea placeholder="Message" allowClear prefix={<MessageOutlined/>}
                                              style={{height: 120}} onChange={(e) => {
                                        this.setState({message: e.target.value})
                                    }}/>
                                </Col>
                                <Col span={this.state.sendSpan} className='margin-bottom-sm'>
                                    <Input size="large" placeholder="CAPTCHA" prefix={<SafetyOutlined/>}
                                           onChange={(e) => {
                                               this.setState({CAPTCHA: e.target.value})
                                           }}/>
                                </Col>
                                <Col span={this.state.sendSpan} className='margin-bottom-sm'>
                                    <Button size='large' style={{width: '100%'}}>{this.state.CAPTCHAS}</Button>
                                </Col>
                                <Col span={this.state.sendSpan} className='margin-bottom-sm'>
                                    <Button size='large' className='bg-yellow' style={{width: '100%'}}
                                            onClick={this.tapSubEvent}>SEND</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            {/*END*/}
        </div>
    }
}

export default RouterModule(Home)