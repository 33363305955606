import React from "react";
import {RouterModule} from "../model/RouterModel";
import {Button, Col, Image, Pagination, Row, Typography} from "antd";
import myApp from "../static/js/MyApp";

const {Paragraph} = Typography;

class ProductList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dbList: [],
            page: 1, size: 8, total: 0,
            spanNumber: [12],
        }
    }

    componentDidMount() {
        this.GetProductList();

        this.setSpan();
        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        let width = window.innerWidth;
        if (width < 900) this.setState({spanNumber: [24]});
        if (width > 900) this.setState({spanNumber: [12]});
    }

    GetProductList = (page) => {
        let params = this.props.router.params,
            url = `${myApp.G_API_HOST}/api/cms/getProduct`,
            body = {
                page: myApp.isEmpty(page) ? this.state.page : page,
                size: this.state.size,
                ids: params.ids === 'all' ? '' : params.ids
            }
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&"
            url += join + key + "=" + body[key];
        }
        myApp.httpGet(url).then(res => {
            if (res.code === 0) {
                const db = res.data,
                    proList = db.list.map(item => {
                        item.product_image = item.product_image.split(",")
                        return item;
                    })
                this.setState({dbList: proList, total: db.total})
            }
        })
    }

    tapChangeEvent = (e) => {
        this.setState({page: e})
        this.GetProductList(e);
    }

    toPageDetails = (event) => {
        this.props.router.navigation("/Products/ProductDetails/" + event.id, {state: {param: event}})
    }

    render() {
        const proList = (this.state.dbList).map((item, index) => {
            return <Col span={this.state.spanNumber} key={index} className='margin-bottom'>
                <Row>
                    <Col span={12} className='padding'>
                        <div className='sub-text-title font_weight_bold'>{item.product_name}</div>
                        <Paragraph>{item.product_abstract}</Paragraph>
                        <Row gutter={16}>
                            <Col span={24} style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                <Button size='large' className='bg-yellow' style={{width: '100%'}} onClick={() => {
                                    this.toPageDetails(item)
                                }}>View Details</Button>
                            </Col>
                            <Col span={24} style={{marginBottom: '1rem'}}>
                                <Button size='large' className='bg-yellow' style={{width: '100%'}} onClick={() => {
                                    this.props.router.navigation("/ContactUs")
                                }}>Inquire Now</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className='padding'>
                        <Image src={item.product_image[0]}/>
                    </Col>
                </Row>
            </Col>
        })
        return (
            <div>
                <div className='padding margin-ls'>
                    <div className='text-title'>Keywords</div>
                    <Paragraph>
                        In the process of internal desktop applications development, many different
                        design
                        specs and
                        implementations would be involved, which might cause designers and
                        developers
                        difficulties and
                        duplication and reduce the efficiency of development.
                    </Paragraph>
                    <Row gutter={45}>
                        {proList}
                    </Row>
                </div>

                <div className='text-align-right margin-sm'>
                    <Pagination defaultCurrent={this.state.page} total={this.state.total}
                                onChange={this.tapChangeEvent}/>
                </div>
            </div>
        )
    }
}

export default RouterModule(ProductList)