import React from "react";
import {RouterModule} from "../model/RouterModel";
import "../static/css/News.css"
import {Col, Image, Pagination, Row, Typography} from "antd";
import pro004 from "../static/image/product/pro003.png"
import myApp from "../static/js/MyApp";
import {ArrowRightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const {Paragraph} = Typography;

class News extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1, size: 6, total: 0,
            dbList: [],
            spanParam: [8, 16],
        }
    }

    componentDidMount() {
        this.getNewList()
        this.onInnerWidth();
        this.setSpan();
    }

    onInnerWidth = () => {
        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        let width = window.innerWidth;
        if (width > 600) {
            this.setState({spanParam: [8, 16]})
        } else {
            this.setState({spanParam: [24, 24]})
        }
    }

    getNewList() {
        let url = `${myApp.G_API_HOST}/api/cms/getNew`, body = {page: this.state.page, size: this.state.size};
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&";
            url += join + key + "=" + body[key];
        }
        myApp.httpGet(url).then(res => {
            if (res.code === 0) {
                const db = res.data,
                    dbList = db.list, total = db.total;
                this.setState({dbList: dbList, total: total})
            }
        })
    }

    getChange = (event) => {
        this.setState({page: event})
    }

    render() {
        const newsList = this.state.dbList.map((item, index) => {
            let html;
            if (index % 2 < 1) {
                html = <Row key={index}>
                    <Col span={this.state.spanParam[0]} className='margin-tb'>
                        <Image src={pro004}/>
                    </Col>
                    <Col span={this.state.spanParam[1]} className='margin-tb'>
                        <div className='padding-lr new_hover'>
                            <div className='sub-text-title font_weight_bold cursor'>{item.n_title}</div>
                            <Link className='cursor'  to={'/DetailPage'} state={item}>
                                <Paragraph className='cursor'>
                                    {item.n_abstract}
                                </Paragraph>
                            </Link>
                            <div className='margin-tb padding text-align-right'>
                                <Link className='cursor'  to={'/DetailPage'} state={item}>View More <ArrowRightOutlined/></Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            } else {
                html = <Row key={index}>
                    <Col span={this.state.spanParam[1]} className='margin-tb'>
                        <div className='padding-lr new_hover'>
                            <div className='sub-text-title font_weight_bold cursor'>{item.n_title}</div>
                            <Link className='cursor'  to={'/DetailPage'} state={item}>
                                <Paragraph className='cursor'>
                                    {item.n_abstract}
                                </Paragraph>
                            </Link>
                            <div className='margin-tb padding text-align-right'>
                                <Link className='cursor'  to={'/DetailPage'} state={item}>View More <ArrowRightOutlined/></Link>
                            </div>
                        </div>
                    </Col>
                    <Col span={this.state.spanParam[0]} className='margin-tb'>
                        <Image src={pro004}/>
                    </Col>
                </Row>
            }
            return html;
        })
        return (
            <div className='content-max-width'>
                {newsList}

                <div className='text-align-center margin-bottom padding-tb'>
                    <Pagination defaultCurrent={this.state.page} total={this.state.total} pageSize={this.state.size}
                                onChange={this.getChange}/>
                </div>
            </div>
        )
    }

}

export default RouterModule(News)