import React from "react";
import {RouterModule} from "../model/RouterModel";
import {Button, Card, Col, Input, message, Row, Typography} from "antd";
import myApp from "../static/js/MyApp";
import {SendOutlined, WhatsAppOutlined} from "@ant-design/icons";
import AMapLoader from "@amap/amap-jsapi-loader";
import TextArea from "antd/es/input/TextArea";

const {Paragraph} = Typography;

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            spanParam: [10, 14],

            name: '',
            tel: '',
            email: '',
            country: '',
            phone: '',
            fax: '',
            company: '',
            message: '',
        })
    }

    sendMessage = async () => {
        const param = new FormData();
        param.append("name", this.state.name);
        param.append("tel", this.state.tel);
        param.append("email", this.state.email);
        param.append("country", this.state.country);
        param.append("phone", this.state.phone);
        param.append("fax", this.state.fax);
        param.append("company", this.state.company);
        param.append("message", this.state.message);
        const response = await myApp.sendMessage(param);
        if (response.code === 0) {
            message.success(myApp.messageSuccess)
        } else {
            message.warning(myApp.messageErrors);
        }
    }

    //渲染成功后进行map对象的创建
    componentDidMount() {
        this.mapInit();
        this.onInnerWidth();
        this.setSpan();
    }

    onInnerWidth = () => {
        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        let width = window.innerWidth;
        if (width > 600) {
            this.setState({spanParam: [10, 14]})
        } else {
            this.setState({spanParam: [24, 24]})
        }
    }

    mapInit = () => {
        AMapLoader.load({
            key: "4b068d48324e48e69afe08d49c61f974",
            version: "1.4.15",
            plugins: ['Map3D', 'AMap.Weather'],
        }).then((AMap) => {
            this.map = new AMap.Map("container", {
                zoom: 14,
                center: [120.114353, 29.31268],
                lang: 'en',
            });
        }).catch(e => {
            console.log(e);
        })
    }

    render() {
        return (
            <div>
                <div className='content-max-width'>
                    <Row>
                        <Col span={this.state.spanParam[0]}>
                            <Card title={myApp.G_COMPANY_NAME}
                                  extra={<a href={'tel:' + myApp.G_TEL}
                                            style={{fontSize: '2rem'}}><WhatsAppOutlined/></a>}>
                                <p>WhatApp： {myApp.G_WHATAPP},</p>
                                <p>Wechat：{myApp.G_WECHAT}</p>
                                <p>Add：{myApp.G_ADDRESS}</p>
                                <p>Tel：{myApp.G_TEL}</p>
                                <Paragraph>
                                    The company is mainly engaged in metal building and decoration materials, expo &
                                    exhibition materials such as aluminum products, galvanized steel products, iron and
                                    steel products etc. Cooperative factories are all over the country to provide buyers
                                    with national goods transfer, national price comparison and multi category shipping
                                    services.

                                    The company has the ability to operate its own import and export, and cooperates
                                    with a number of international freight forwarders. The freight forwarding company is
                                    located in Tianjin port, Ningbo port, Shenzhen port, etc. The products are exported
                                    to Africa, Europe and America. It provides buyers with a full set of LCL and space
                                    booking services to realize door-to-door one-stop service.
                                </Paragraph>
                            </Card>
                        </Col>
                        <Col span={this.state.spanParam[1]}>
                            <div className='margin padding'>
                                <p><Input addonBefore="Name" size={"large"} onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }}/></p>
                                <p><Input addonBefore="Tel" size={"large"} onChange={(e) => {
                                    this.setState({tel: e.target.value})
                                }}/></p>
                                <p><Input addonBefore="E-mail" size={"large"} onChange={(e) => {
                                    this.setState({email: e.target.value})
                                }}/></p>
                                <p><Input addonBefore="Country" size={"large"} onChange={(e) => {
                                    this.setState({country: e.target.value})
                                }}/></p>
                                <p><Input addonBefore="Phone" size={"large"} onChange={(e) => {
                                    this.setState({phone: e.target.value})
                                }}/></p>
                                <p><Input addonBefore="Fax" size={"large"} onChange={(e) => {
                                    this.setState({fax: e.target.value})
                                }}/></p>
                                <p><Input addonBefore="Company" size={"large"} onChange={(e) => {
                                    this.setState({company: e.target.value})
                                }}/></p>
                                <p>
                                    <TextArea placeholder="Message" autoSize={{minRows: 3, maxRows: 5}}
                                              onChange={(e) => {
                                                  this.setState({message: e.target.value})
                                              }}/>
                                </p>
                                <div>
                                    <Button icon={<SendOutlined/>} size="large" className='bg-yellow'
                                            style={{width: '100%'}} onClick={this.sendMessage}>
                                        SEND
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='text-align-center'>
                    <div id="container" tabIndex="0" lang='en'></div>
                </div>
            </div>
        )
    }
}

export default RouterModule(ContactUs)