import React from "react";
import {RouterModule} from "../../model/RouterModel";
import {BackTop, Col, Image, Row, Typography} from "antd";
import logo from "../../static/image/footer_logo.png"
import myApp from "../../static/js/MyApp";
import {Link} from "react-router-dom";

const {Title} = Typography;

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            span: 12,
            titleLevel: 1,
        }
    }

    componentDidMount() {
        this.onWindowsSize();
    }

    onWindowsSize = () => {
        let width = window.innerWidth;
        if (width < 900) {
            this.setState({span: 24, titleLevel: 3})
        } else {
            this.setState({span: 12, titleLevel: 1})
        }

        window.onresize = (e) => {
            width = e.currentTarget.innerWidth
            if (width < 900) {
                this.setState({span: 24, titleLevel: 3})
            } else {
                this.setState({span: 12, titleLevel: 1})
            }
        }
    }

    render() {
        return <footer className='footer'>
            <div className='footer_start padding footer '>
                <div className='content-max-width'>
                    <Row>
                        <Col span={this.state.span}>
                            <Title type='warning' level={3}>{myApp.G_COMPANY_NAME}</Title>
                            <p>Address：{myApp.G_ADDRESS}</p>
                            <p>Tel：{myApp.G_TEL}</p>
                            <p>Fax：{myApp.G_FAX}</p>
                            <p>WhatsApp：{myApp.G_WHATAPP}</p>
                            <p>Skype：{myApp.G_Skype}</p>
                            <p>E-mail：{myApp.G_Email}</p>
                        </Col>
                        <Col span={this.state.span}>
                            <Row>
                                <Col span={10} className='text-align-left footer_nav'>
                                    <Title type='warning' level={this.state.titleLevel}>Navigation</Title>
                                    <p>
                                        <Link to='/'>Home</Link>
                                    </p>
                                    <p>
                                        <Link to='/AboutUs'>About us</Link>
                                    </p>
                                    <p>
                                        <Link to='/Products/all'>Products</Link>
                                    </p>
                                    <p>
                                        <Link to='/Application'>Application</Link>
                                    </p>
                                    <p>
                                        <Link to='/News'>News</Link>
                                    </p>
                                    <p>
                                        <Link to='/ContactUs'>Contact us</Link>
                                    </p>
                                </Col>
                                <Col span={14} className='text-align-center'>
                                    <Image src={logo} preview={false} className='footer_logo'></Image>
                                    <div className='margin-top padding-top footer_icon'>
                                        <i className='margin-right iconfont icon-facebook'/>
                                        <i className='margin-right iconfont icon-hi'/>
                                        <i className='margin-right iconfont icon-telegram'/>
                                        <i className='margin-right iconfont icon-weixin'/>
                                        <i className='margin-right iconfont icon-whatsapp'/>
                                        <i className='margin-right iconfont icon-line-line'/>
                                        <i className='margin-right iconfont icon-kakao-talk-line'/>
                                        <i className='margin-right iconfont icon-youtube'/>
                                        <i className='iconfont icon-LinkedinIn'/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='footer_end padding footer '>
                <div className='content-max-width'>
                    <div>Links: aaa aaa</div>
                    <div>Copyright © {myApp.G_COMPANY_NAME} All Rights Reserved | Sitemap | Technical Support Reanod
                    </div>
                </div>
            </div>
            <BackTop/>
        </footer>
    }
}

export default RouterModule(Footer)