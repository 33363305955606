import React from "react";
import {RouterModule} from "../model/RouterModel";
import {Col, Image, Pagination, Row, Typography} from "antd";
import pro004 from "../static/image/product/pro004.png"
import myApp from "../static/js/MyApp";
import {ArrowRightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const {Paragraph} = Typography;

class Application extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dbList: [],
            page: 1, size: 6, total: 0,
            spanParam: [8, 16],
        }
    }

    componentDidMount() {
        this.getArticle();
        this.onInnerWidth();
        this.setSpan();
    }

    onInnerWidth = () => {
        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        let width = window.innerWidth;
        if (width > 600) {
            this.setState({spanParam: [8, 16]})
        } else {
            this.setState({spanParam: [24, 24]})
        }
    }

    getArticle() {
        let url = `${myApp.G_API_HOST}/api/cms/getArticle`, body = {page: this.state.page, size: this.state.size};
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&";
            url += join + key + "=" + body[key];
        }
        myApp.httpGet(url).then(res => {
            if (res.code === 0) {
                const db = res.data;
                this.setState({dbList: db.list, total: db.total})
            }
        })
    }

    getChange = (event) => {
        this.setState({page: event})
        setTimeout(() => {
            this.getArticle()
        }, 500)
    }

    render() {
        const newList = this.state.dbList.map((item, index) => {
            let html = '';
            if (index % 2 >= 1) {
                html = <Row key={index}>
                    <Col span={this.state.spanParam[0]}>
                        <div className='padding-sm margin-sm'><Image src={pro004} style={{width: '100%'}}/></div>
                    </Col>
                    <Col span={this.state.spanParam[1]}>
                        <div className='padding margin'>
                            <div className='text-title text-color-yellow font_weight_bold'>{item.a_title}</div>
                            <Link to={'/DetailPage'} state={item}>
                                <Paragraph>{item.a_abstract}</Paragraph>
                            </Link>
                            <div className='margin-tb padding-top text-align-right'>
                                <Link className='button-main' to={'/DetailPage'} state={item}>View More <ArrowRightOutlined/></Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            } else {
                html = <Row key={index}>
                    <Col span={this.state.spanParam[1]}>
                        <div className='padding margin'>
                            <div className='text-title text-color-yellow font_weight_bold'>{item.a_title}</div>
                            <Link  to={'/DetailPage'} state={item}>
                                <Paragraph>{item.a_abstract}</Paragraph>
                            </Link>
                            <div className='margin-tb padding-top text-align-left'>
                                <Link className='button-main'  to={'/DetailPage'} state={item}>View
                                    More <ArrowRightOutlined/></Link>
                            </div>
                        </div>
                    </Col>
                    <Col span={this.state.spanParam[0]}>
                        <div className='padding-sm margin-sm'><Image src={pro004} style={{width: '100%'}}/></div>
                    </Col>
                </Row>
            }
            return html;
        })

        return (
            <div className='content-max-width'>
                {newList}

                <div className='text-align-center margin-tb'>
                    <Pagination defaultCurrent={this.state.page} total={this.state.total} pageSize={this.state.size}
                                onChange={this.getChange}/>
                </div>
            </div>
        )
    }

}

export default RouterModule(Application)