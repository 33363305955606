import React from "react";
import {RouterModule} from "../model/RouterModel";
import Header from "./common/Header";
import Footer from "./common/Footer";
import {Outlet} from "react-router";
import Title from "antd/es/skeleton/Title";
import MyApp from "../static/js/MyApp";

class IndexHome extends React.Component {

    constructor(props) {
        super(props);
        Title(MyApp.G_TITLE)
    }

    render() {
        return <>
            <Header/>
            <main className='main'>
                <Outlet></Outlet>
            </main>
            <Footer/>
        </>
    }
}

export default RouterModule(IndexHome)