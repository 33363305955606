import React from "react";
import {RouterModule} from "../model/RouterModel";
import myApp from "../static/js/MyApp";
import {Card, Col, Divider, Row, Typography} from "antd";
import Meta from "antd/es/card/Meta";

const {Paragraph} = Typography;

class DetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            param: {title: '', abstract: '', className: '', body: '', createTime: '', updateTime: '', author: ''},
            ProductReponse: {},
            proTopList: '',
            proTopHtml: '',
            proList: '',
            productHtml: '',
            hotProSpan: 8,
        }
    }

    componentDidMount = () => {
        this.ParamerInit();
        this.getProductDB();
        this.setSpan()

        window.addEventListener('resize', () => {
            this.setSpan();
        });
    }

    setSpan = () => {
        let width = window.innerWidth;
        if (width < 900) {
            this.setState({hotProSpan: 12});
        }
        if (width < 500) {
            this.setState({hotProSpan: 24});
        }
        if (width > 900) {
            this.setState({hotProSpan: 8});
        }
        this.getProduct();
    }

    ParamerInit = () => {
        const param = this.props.router.location.state
        let item = {}
        if (myApp.isEmpty(param.a_title)) {
            item = {
                title: param.n_title, abstract: param.n_abstract, className: param.className, body: param.n_body,
                createTime: param.create_time, updateTime: param.update_time, author: param.n_author,
            }
        } else {
            item = {
                title: param.a_title, abstract: param.a_abstract, className: param.theirName, body: param.a_body,
                createTime: param.create_time, updateTime: param.update_time, author: '',
            }
        }
        this.setState(() => ({param: item}))
    }

    getProductDB = async () => {
        let url = `${myApp.G_API_HOST}/api/cms/getProduct`, body = {page: 1, size: 8}
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&";
            url += join + key + "=" + body[key];
        }
        const ProductReponse = await myApp.httpGet(url);
        this.setState({ProductReponse: ProductReponse})
        this.getProduct();
    }

    getProduct = () => {
        const res = this.state.ProductReponse
        if (res.code === 0) {
            const db = res.data
            // 格式化商品数据
            let proList = db.list.map(item => {
                item.product_color = myApp.isEmpty(item.product_color) ? [] : item.product_color.split(",")
                let img = item.product_image;
                item.product_image = myApp.isEmpty(img) ? [] : typeof img == 'object' ? img : img.split(",")
                item.product_key = myApp.isEmpty(item.product_key) ? [] : item.product_key.split(",")
                return item;
            })
            // 生成展示数据
            let hotProduct = proList.map((item, index) => {
                return <Col className="gutter-row" span={this.state.hotProSpan} key={index}>
                    <Card hoverable className='margin-tb hot-product' style={{width: '100%'}}
                          cover={<img alt="example" src={item.product_image[0]}/>}>
                        <Meta title="Europe Street beat" description={item.product_abstract}/>
                    </Card>
                </Col>
            });
            // 生成展示置顶数据
            let topList = db.top.map(item => {
                item.product_color = myApp.isEmpty(item.product_color) ? [] : item.product_color.split(",")
                let img = item.product_image;
                item.product_image = myApp.isEmpty(item.product_image) ? [] : typeof img == "object" ? img : item.product_image.split(",")
                item.product_key = myApp.isEmpty(item.product_key) ? [] : item.product_key.split(",")
                return item;
            })

            let proTopHmtl = topList.map((item, index) => {
                return <Col span={this.state.topProSpan} key={index}>
                    <div className='product background_style'
                         style={{backgroundImage: 'url(' + item.product_image[0] + ')'}}>
                        <div className='product_hover'>
                            <div className='product_title'>{item.product_name}</div>
                            <div className='product_body padding'>{item.product_abstract}</div>
                        </div>
                    </div>
                </Col>
            })

            this.setState({
                proTopList: topList,
                proTopHtml: proTopHmtl,
                proList: proList,
                productHtml: hotProduct,
            })
        }
    }

    render() {
        return (<div className="content-max-width">
            <div className='text-title text-color-yellow text-align-center margin-tb padding-top font_weight_bold'>
                {this.state.param.title}
            </div>
            <div className='margin padding-sm text-align-center'>{this.state.param.updateTime}</div>
            <div className='margin padding-sm text-align-center'>{this.state.param.abstract}</div>
            <Paragraph className='padding'>
                <p dangerouslySetInnerHTML={{__html: this.state.param.body}}></p>
            </Paragraph>
            <Divider></Divider>
            {/*
            <Row>
                <Col span={12}>
                    <a href={'/DetailPage'} className='a_lineheight'>
                        <Button type="text" icon={<LeftOutlined/>} size="large">
                            The previous article
                        </Button>
                    </a>
                </Col>
                <Col span={12} className='text-align-right'>
                    <a href={'/DetailPage'} className='a_lineheight'>
                        <Button type="text" size="large">
                            Next article <RightOutlined/>
                        </Button>
                    </a>
                </Col>
            </Row>
*/}

            <div className='margin-top padding-tb'>


                <div className="text-align-center padding-tb">
                    <div className='banner_title'>Hot Products</div>
                    <Paragraph>
                        Shijiazhuang Reanod internet Technology Co., Ltd. was established by foreign trade staff
                        after
                        studying from overseas in 2008, the team was composed.
                    </Paragraph>
                    <div className='transverse-line margin-tb'></div>
                </div>
                <div>
                    <Row>{this.state.productHtml}</Row>
                </div>
            </div>
        </div>)
    }
}

export default RouterModule(DetailPage)