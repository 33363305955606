import React from "react";
import {RouterModule} from "../../model/RouterModel";
import logo from "../../static/image/logo.png"
import {Image} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pathname: '/',
            menuType: false,
        }
    }

    componentDidMount() {
        this.navigationClass()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const pathname = prevState.pathname, location = this.props.router.location.pathname
        if (pathname !== location) {
            this.navigationClass()
        }
    }

    navigationClass = () => {
        const pathname = this.props.router.location.pathname
        this.setState({pathname: pathname})
    }

    tapPage = (pageName) => {
        this.props.router.navigation(pageName)
        this.setState({
            pathname: pageName,
            menuType: false
        })
    }

    tapOpenMenu = () => {
        this.setState({menuType: true})
    }

    render() {
        /*导航生成*/
        const navigationList = [
            {title: 'Home', pathname: '/'},
            {title: 'AboutUs', pathname: '/AboutUs'},
            {title: 'Products', pathname: '/Products/all'},
            {title: 'Application', pathname: '/Application'},
            {title: 'News', pathname: '/News'},
            {title: 'ContactUs', pathname: '/ContactUs'},
        ].map((item) => {
            return <li key={item.title} onClick={() => {
                this.tapPage(item.pathname)
            }} className={this.state.pathname === item.pathname ? 'header_target' : ''}>{item.title}</li>
        })

        /* END */
        return <header className='bg-yellow'>
            <div className='header_main padding content-max-width'>
                <div>
                    <div className='header_logo'>
                        <Image src={logo} preview={false}/>
                    </div>
                    <div className='header_navigation margin-left text-align-right'>
                        <ul className='header_nav_ul'>{navigationList}</ul>
                    </div>
                    <div className='header_nav_icon'>
                        <span onClick={this.tapOpenMenu}><UnorderedListOutlined/></span>
                    </div>
                    <div className={this.state.menuType ? 'header_model header_model-s' : 'header_model'}>
                        <ul className='header_nav_ul'>{navigationList}</ul>
                    </div>
                </div>
            </div>
        </header>
    }
}

export default RouterModule(Header)