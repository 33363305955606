import React from "react";
import {RouterModule} from "../model/RouterModel";
import {Button, Layout, Menu,} from "antd";
import {AlignLeftOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {Outlet} from "react-router";
import myApp from "../static/js/MyApp";


const {Sider, Content} = Layout;


class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: [],
            navList: [],
            menuType: false,
        }
    }

    componentDidMount() {
        this.getProductClass();
    }

    getItem(label, key, icon, children, type) {
        return {key, icon, children, label, type};
    }

    getProductClass() {
        let url = `${myApp.G_API_HOST}/api/cms/getProductClass`, body = {page: 1, size: 1000};
        for (const key in body) {
            let join = url.indexOf("?") <= -1 ? "?" : "&";
            url += join + key + "=" + body[key]
        }
        myApp.httpGet(url).then((res) => {
            if (res.code === 0) {
                const db = res.data;
                const list = db.list.map(res => {
                    res.children = [];
                    return res;
                })
                this.setState({navList: list})
                this.GetNavigation();
            }
        })
    }

    GetNavigation = (ids = 0) => {
        for (const key in this.state.navList) {
            let item = this.state.navList[key];
            if (item.ids === ids) {
                this.state.navList[key].children.push(this.GetNavigation(item.id))
            }
        }
        setTimeout(() => {
            this.NavationgInit();
        }, 500)
    }

    NavationgInit = () => {
        const db = this.state.navList;
        let nav = [];
        for (const key in db) {
            // 首选对子类别进行初始化
            let cnArr = [], item = db[key];
            if (myApp.isEmpty(item.children)) {
                for (const cnArrKey in item.children) {
                    let res = item.children[cnArrKey];
                    if (!myApp.isEmpty(res)) cnArr.push(this.getItem(res.class_name, res.id));
                }
            }
            // 初始化子类后，赋值于父类
            let ts;
            if (cnArr.length > 0) {
                ts = this.getItem(item.class_name, item.id, <UnorderedListOutlined/>, cnArr)
            } else {
                ts = this.getItem(item.class_name, item.id, <AlignLeftOutlined/>)
            }
            nav.push(ts);
        }
        console.log(nav)
        this.setState({item: nav})
    }

    onClick = (e) => {
        console.log('click ', e);
        this.setState({menuType: false})
    };

    onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    }

    openMenu = () => {
        this.setState({menuType: true});
        console.log(this.state.menuType);
    }

    render() {
        return (
            <div className='content-max-width'>
                <Layout>
                    <Layout>
                        <Sider theme='light' className={'classify_hide'}>
                            <Menu onClick={this.onClick} style={{width: '100%'}} defaultSelectedKeys={['1']}
                                  defaultOpenKeys={['sub1']} mode="inline" items={this.state.item}/>
                        </Sider>
                        <Content>
                            <div className='text-align-center margin-sm classify_menu'>
                                <Button icon={<UnorderedListOutlined/>} size={'large'} style={{minWidth: '50%'}}
                                        onClick={this.openMenu}>
                                    Open the category menu
                                </Button>
                                <div
                                    className={this.state.menuType ? 'classify_show classify_list' : 'classify_hide classify_list'}>
                                    <Menu onClick={this.onClick} style={{width: '100%'}} defaultSelectedKeys={['1']}
                                          defaultOpenKeys={['sub1']} mode="inline" items={this.state.item}/>
                                </div>
                            </div>
                            <Outlet></Outlet>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }

}

export default RouterModule(Products)